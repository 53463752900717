<!--
  This template displays a heading for a record (from a COLLECTION field) plus:
  * Shows a "cross" for removing the record if COLLECTION has a "newRecordTemplate" parameter
  * Shows record auto-matching status & options if COLLECTION has "ernMatch" parameter and is conflictAlerting AB or AB_GROUP
-->
<template>
  <div class="FieldCollectionActions" v-if="unMatchedOptionsVisible">
    <!-- Standard unmatched ERN record message and actions -->
    <div
      v-if="modelRow.isUnmatchedErnRecord && modelRow.resolution"
      aria-live="polite"
      class="FieldAlert"
    >
      <div
        class="tickCircle"
        v-if="modelRow.resolution.type === RESOLUTION_TYPE.KEEP"
      >
        <div>
          <font-awesome-icon focusable="false" icon="check" />
        </div>
      </div>
      <div
        class="minusCircle"
        v-if="modelRow.resolution.type === RESOLUTION_TYPE.REMOVE"
      >
        <div>
          <font-awesome-icon focusable="false" icon="minus" />
        </div>
      </div>
      <span
        v-if="modelRow.resolution.type === RESOLUTION_TYPE.KEEP"
        class="resolutionMessage"
        data-unmatched-kept
        >Resolved by {{ modelRow.resolution.staffName }}</span
      >
      <span v-else class="resolutionMessage" data-unmatched-removed
        >Removed by {{ modelRow.resolution.staffName }}. Existing ERN record
        will be removed.</span
      >
      <button
        v-if="!isEditing"
        type="button"
        class="resolve unstyled"
        @click.stop="undoResolve()"
        title="Revert to the unresolved value and unlock"
        ref="resolveButton"
      >
        Undo
      </button>
    </div>
    <div v-else-if="modelRow.isUnmatchedErnRecord" class="unmatchedErnRecord">
      <div class="alertIcon">
        <font-awesome-icon
          class="icon"
          focusable="false"
          icon="exclamation-triangle"
        />
      </div>
      <span>Unmatched ERN record</span>
      <button
        v-if="!isEditing"
        type="button"
        class="resolve unstyled"
        @click="resolve(RESOLUTION_TYPE.KEEP)"
        :title="'Keep ' + heading"
      >
        Keep
      </button>
      <button
        v-if="!isEditing"
        type="button"
        class="resolve unstyled"
        @click="resolve(RESOLUTION_TYPE.REMOVE)"
        :title="'Remove ' + heading"
      >
        Remove
      </button>
      <DialogGeneric
        title="Remove contact detail?"
        max-width="636px"
        icon="mdi-trash-can-outline"
        display-close-btn
        :message="removeMessage"
        :actions="dialogButtons"
        @click:action="clickAction"
        v-model="displayRemoveConfirmDialog"
      />
    </div>
    <div
      v-if="
        modelRow.isMatchedRecord &&
        !isContactTypeEmail &&
        (!contactTypeMatch || !contactCommentsMatch)
      "
      aria-live="polite"
      class="conflictAlert"
    >
      <div>
        <v-icon>mdi-alert-circle-outline</v-icon>
        <span class="pl-3">
          <b>Phone number match found in ERN</b><br />
          <span class="pl-9"
            >We will update the matched phone number on the existing ERN record
            with the new details above.</span
          >
        </span>
      </div>
      <AdsButton
        type="button"
        tertiary
        @click.stop="displayConflict()"
        button-text="View ERN record"
      />
      <DialogGeneric
        title="ERN values"
        max-width="636px"
        icon="swap_calls"
        display-close-btn
        :message="message"
        v-model="displayMoreInfoDialog"
      />
    </div>
  </div>
</template>

<script>
import { AdsButton, DialogGeneric } from '@nswdoe/doe-ui-core'
import { STREAM, CONFLICT_ALERTING, RESOLUTION_TYPE, MATCH } from '@/constants'
import UTILS from '@/store/utils'
import fieldHelperMixin from '@/helpers/fieldHelperMixin'
import { getContactText } from '@/applicationDefinition/helpers'
import { mapGetters } from 'vuex'

export default {
  name: 'RecordActions',
  components: {
    AdsButton,
    DialogGeneric
  },
  props: {
    modelRow: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      displayMoreInfoDialog: false,
      displayRemoveConfirmDialog: false,
      dialogButtons: [
        { name: 'Cancel', color: 'primary', outlined: true },
        { name: 'Remove', color: '#B81237' }
      ],
      CONFLICT_ALERTING,
      RESOLUTION_TYPE
    }
  },
  mixins: [fieldHelperMixin],
  computed: {
    ...mapGetters(['currentStream']),
    isContactTypeEmail() {
      return getContactText(this.modelRow.ernValue) === 'E-Mail'
    },
    contactTypeMatch() {
      return UTILS.isMatch(this.modelRow.oesValue, this.modelRow.ernValue, {
        contactType: MATCH.FUZZY
      })
    },
    contactCommentsMatch() {
      return UTILS.isMatch(this.modelRow.oesValue, this.modelRow.ernValue, {
        comments: MATCH.FUZZY
      })
    },
    message() {
      return `<div style="box-shadow: 0px 2px 3px 2px #00000024; padding: 24px 35px;">
               <p style="font-size: 20px; color: #121212; font-weight: bold">${
                 this.modelRow.parent.parent.label
               }</p>
               <div style="color: #2E2F30"><b>Type:</b> ${
                 getContactText(this.modelRow.ernValue) || 'Contact details'
               }${
        this.contactTypeMatch
          ? '<span style="color: green;padding-left: 12px;font-size: 16px;"><span class="mdi mdi-check-circle"></span><span style="padding-left: 4px;">Match<span></span>'
          : ''
      }</div>
               <div style="color: #2E2F30"><b>Number:</b> ${
                 this.modelRow.ernValue.contactValue || '-Empty-'
               }<span style="color: green;padding-left: 12px;font-size: 16px;"><span class="mdi mdi-check-circle"></span><span style="padding-left: 4px;">Match<span></span></div>
               <div style="color: #2E2F30"><b>Comments:</b> ${
                 this.modelRow.ernValue.comments || '-Empty-'
               }${
        this.contactCommentsMatch
          ? '<span style="color: green;padding-left: 12px;font-size: 16px;"><span class="mdi mdi-check-circle"></span><span style="padding-left: 4px;">Match<span></span>'
          : ''
      }</div>
              </div>`
    },
    removeMessage() {
      return `<p style="color: #121212;">Are you sure you want to remove the following contact detail from ERN:<p><div style="box-shadow: 0px 2px 3px 2px #00000024; padding: 24px 35px; margin-bottom: 16px;">
               <p style="font-size: 20px; color: #121212; font-weight: bold">${
                 this.modelRow.parent.parent.label
               }</p>
               <div style="color: #2E2F30"><b>Type:</b> ${
                 getContactText(this.modelRow.oesValue) || 'Contact details'
               }</div>
               <div style="color: #2E2F30"><b>Number:</b> ${
                 this.modelRow.oesValue.contactValue || '-Empty-'
               }</div>
               <div style="color: #2E2F30"><b>Comments:</b> ${
                 this.modelRow.oesValue.comments || '-Empty-'
               }</div>
              </div>`
    },
    heading() {
      return this.modelRow.label
    },
    isArchived() {
      return this.currentStream === STREAM.ARCHIVE
    },
    unMatchedOptionsVisible() {
      return (
        !this.isPrintPreview &&
        !this.isSentToErn &&
        !this.isWithdrawn &&
        !this.isArchived &&
        !this.isInvalid &&
        !this.isInternalTransfer
      )
    }
  },
  methods: {
    displayConflict() {
      this.displayMoreInfoDialog = true
    },
    resolve(action) {
      if (action === RESOLUTION_TYPE.KEEP) {
        // Construct the resolution object...
        const resolution = {
          id: this.modelRow.id,
          type: RESOLUTION_TYPE.KEEP,
          staffName: this.$store.state.userName,
          date: this.$moment().format('YYYY-MM-DD HH:mm'),
          originalValue: false,
          originalErnValue: this.modelRow.parent.ernValue[0]
        }
        this.save(resolution)
      }
      if (action === RESOLUTION_TYPE.REMOVE) {
        this.displayRemoveConfirmDialog = true
      } else {
        return
      }
    },
    async clickAction(action) {
      if (action === 'Remove') {
        // Construct the resolution object...
        const resolution = {
          id: this.modelRow.id,
          type: RESOLUTION_TYPE.REMOVE,
          staffName: this.$store.state.userName,
          date: this.$moment().format('YYYY-MM-DD HH:mm'),
          originalValue: false,
          originalErnValue: this.modelRow.parent.ernValue[0]
        }
        await this.save(resolution)
        this.displayRemoveConfirmDialog = false
      } else {
        this.displayRemoveConfirmDialog = false
      }
    },
    save(resolution) {
      this.$store.dispatch('updateAlertResolution', [this.modelRow, resolution])
    },
    undoResolve() {
      if (this.modelRow.isResolved) {
        // If already resolved, undo resolution...
        this.save(null)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.FieldCollectionActions {
  padding: 0 0 1rem 1rem;
  .alertIcon {
    color: $color-red;
    display: inline-block;
    margin-right: 0.25rem;
    font-size: 0.9rem;
  }
  .unmatchedErnRecord {
    display: inline-block;
    font-size: 0.9rem;
    font-weight: normal;
    vertical-align: middle;
    span {
      color: $color-red;
      display: inline-block;
      vertical-align: middle;
      margin-right: 4px;
    }
  }
  .resolve {
    text-decoration: underline;
    color: $color-secondary;
    margin-left: 1rem;
  }
  .conflictAlert {
    padding-top: 8px;
    margin-right: 1rem;
    background-color: $grey-light-10;
  }
  ::v-deep .conflictAlert .v-btn {
    margin-left: 12px;
    text-decoration: underline;
    height: 30px;
  }

  ::v-deep .conflictAlert .buttonText {
    color: $color-primary-lighten-1;
  }
  .FieldAlert {
    color: $color-red;
    display: flex;
    padding-top: 0.3rem;
    font-size: 0.9rem;

    .icon {
      font-size: 1rem;
      &.greyed {
        color: silver;
      }
    }
    .resolutionMessage {
      display: inline-block;
      color: $color-text-body;
      font-style: italic;
      padding: 0.6em 0.25em 0 0.25em;
    }
    .tickCircle {
      padding: 0.4em 0.25em 0 0;
      div {
        color: white;
        background-color: green;
        border-radius: 1em;
        line-height: 1;
        font-size: 0.7em;
        padding: 0.5em;
        svg {
          vertical-align: -0.2em;
        }
      }
    }
    .minusCircle {
      padding: 0.4em 0.25em 0 0;
      div {
        color: white;
        background-color: gray;
        border-radius: 1em;
        line-height: 1;
        font-size: 0.7em;
        padding: 0.5em;
        svg {
          vertical-align: -0.2em;
        }
      }
    }
  }
}
</style>
