<template>
  <div>
    <v-col class="pt-0 pl-0 pr-0">
      <TabNavigation v-model="tab" :items="items" text-transform="none">
        <template slot="content1">
          <v-row class="mx-10">
            <Y67TPrimaryEoiList
              @receivedDatefilter="receivedEoisCountRefresh"
            />
          </v-row>
        </template>
        <template slot="content2">
          <v-row class="mx-10">
            <Y67TPrimaryStudentList
              @refresh="redirectToReceivedEois"
              @refreshUnlinkedStudents="refreshUnlinkedStudents"
            />
          </v-row>
        </template>
        <template slot="content3">
          <v-row class="mx-10">
            <Y67TTrackingSheetList
              @receivedDatefilter="receivedHSCountRefresh"
              @refresh="redirectToReceivedEois"
            />
          </v-row>
        </template>
      </TabNavigation>
    </v-col>
  </div>
</template>

<script>
import Y67TPrimaryEoiList from '@/components/applicationList/Y67TPrimaryEoiList.vue'
import Y67TPrimaryStudentList from '@/components/applicationList/Y67TPrimaryStudentList.vue'
import Y67TTrackingSheetList from '@/components/applicationList/Y67TTrackingSheetList.vue'
import { TabNavigation } from '@nswdoe/doe-ui-core'
import { mapGetters } from 'vuex'
import { Y67T_STATUS } from '@/constants'

const currentYear = new Date().getFullYear().toString()

export default {
  name: 'Y67TPrimaryDataTable',
  components: {
    Y67TPrimaryEoiList,
    Y67TPrimaryStudentList,
    Y67TTrackingSheetList,
    TabNavigation
  },
  data() {
    return {
      tab: null,
      selectedOptions: [currentYear],
      activeTab: '',
      schoolChange: false,
      refreshedUnlinkedStudents: false
    }
  },
  computed: {
    ...mapGetters({
      y67tApplications: 'y67tApplications',
      schoolCode: 'selectedSchoolCode',
      isSpinner: 'isSpinner',
      y67tSearchFilter: 'y67tSearchFilter'
    }),
    eoiApplications() {
      if (this.y67tApplications && this.y67tApplications.length > 0) {
        const applications = this.y67tApplications(this.schoolCode).filter(
          (a) =>
            a.applicationStatus !== 'AutoDraft' &&
            a.preferenceNo !== -3 &&
            (this.selectedOptions?.length
              ? this.selectedOptions.includes(
                  new Date(a.dateReceived).getFullYear().toString()
                )
              : true)
        )
        return applications.length
      }
      return null
    },
    studentData() {
      if (this.y67tApplications && this.y67tApplications.length > 0) {
        const applications = this.y67tApplications(this.schoolCode).filter(
          (app) => app.applicationStatus === 'AutoDraft'
        )
        return applications.length
      }
      return null
    },
    trackingSheetData() {
      if (this.y67tApplications && this.y67tApplications.length > 0) {
        const applications = this.y67tApplications(this.schoolCode).filter(
          (app) =>
            (app.applicationStatus === Y67T_STATUS.SENT_TO_HS ||
              (app.applicationStatus === Y67T_STATUS.NOT_REQUIRED &&
                app?.localHighSchool?.schoolName?.length > 0)) &&
            new Date(app.dateReceived).getFullYear().toString() === currentYear
        )
        return applications.length
      }
      return null
    },
    allApps() {
      const applications = this.y67tApplications(this.schoolCode).filter(
        (app) =>
          app.applicationStatus === Y67T_STATUS.SENT_TO_HS ||
          app.applicationStatus === Y67T_STATUS.NOT_REQUIRED ||
          app.applicationStatus === 'AutoDraft'
      )
      return applications.length
    },
    eoiTabName() {
      if (this.eoiApplications) {
        return `RECEIVED EOI<small>s</small> (${this.eoiApplications})<span hidden>(${this.allApps})</span> <span hidden>(${this.trackingSheetData})</span> <span hidden>(${this.studentData})</span>`
      }
      return `RECEIVED EOI<small>s</small>`
    },
    studentTabName() {
      if (this.studentData) {
        return `UNLINKED STUDENTS (${this.studentData})`
      }
      return 'UNLINKED STUDENTS'
    },
    trackingSheetList() {
      if (this.trackingSheetData) {
        return `HIGH SCHOOL TRACKING (${this.trackingSheetData})`
      }
      return 'HIGH SCHOOL TRACKING'
    },
    items() {
      return [
        { tab: `${this.eoiTabName}` },
        { tab: `${this.studentTabName}` },
        { tab: `${this.trackingSheetList}` }
      ]
    }
  },
  created() {
    // load the list on first load
    this.$store.commit('setY67tSearchFilter', { tabName: 'RESET' })
    this.$store.dispatch('getSchoolCustomisation', this.schoolCode)
    this.$store.dispatch('getY67TApplications')
  },
  watch: {
    schoolCode() {
      this.$store.commit('setY67tSearchFilter', { tabName: 'RESET' })
      this.schoolChange = true
      this.activeTab = document
        .getElementsByClassName('v-tab--active')[0]
        .innerText.split(' ')[0]
      this.$store.dispatch('getSchoolCustomisation', this.schoolCode)
      this.$store.dispatch('getY67TApplications').then(() => {
        this.$nextTick(() => this.stayTab())
      })
    },
    allApps() {
      if (this.schoolChange === false) {
        this.redirectToReceivedEois()
      }
    },
    eoiApplications() {
      if (this.schoolChange === false) {
        this.redirectToReceivedEois()
      }
    },
    refreshedUnlinkedStudents() {
      if (this.refreshedUnlinkedStudents) {
        this.activeTab = 'UNLINKED'
        setTimeout(
          () => {
            this.$nextTick(() => this.stayTab())
          },
          this.isSpinner === false ? 0 : 3000
        )
      }
    }
  },
  methods: {
    redirectToReceivedEois() {
      //We have added plain javascript because v-model tab actions are not working at the moment.
      this.$nextTick(() => {
        document.getElementsByClassName('v-tab')[0].click()
      })
    },
    refreshUnlinkedStudents() {
      this.refreshedUnlinkedStudents = true
    },
    stayTab() {
      this.activeTab === 'HIGH'
        ? document.getElementsByClassName('v-tab')[2].click()
        : this.activeTab === 'UNLINKED'
        ? document.getElementsByClassName('v-tab')[1].click()
        : document.getElementsByClassName('v-tab')[0].click()
      setTimeout(
        () => {
          this.schoolChange = false
        },
        this.isSpinner === false ? 0 : 3000
      )
    },
    receivedEoisCountRefresh(selectedOptions) {
      let applications = []
      if (this.y67tApplications && this.y67tApplications.length > 0) {
        applications = this.y67tApplications(this.schoolCode).filter(
          (a) =>
            a.applicationStatus !== 'AutoDraft' &&
            a.preferenceNo !== -3 &&
            (selectedOptions?.length
              ? selectedOptions.includes(
                  new Date(a.dateReceived).getFullYear().toString()
                )
              : true)
        )
      }
      //We have added plain javascript because v-model tab actions are not working at the moment.
      document.getElementsByClassName(
        'v-tab'
      )[0].innerHTML = `RECEIVED EOI<small>s</small>&nbsp;(${applications.length})`
    },
    receivedHSCountRefresh(value) {
      let applications = []
      if (this.y67tApplications && this.y67tApplications.length > 0) {
        applications = this.y67tApplications(this.schoolCode).filter(
          (a) =>
            (a.applicationStatus === Y67T_STATUS.SENT_TO_HS ||
              (a.applicationStatus === Y67T_STATUS.NOT_REQUIRED &&
                a?.localHighSchool?.schoolName?.length > 0)) &&
            new Date(a.dateReceived).getFullYear().toString() === value
        )
      }
      //We have added plain javascript because v-model tab actions are not working at the moment.
      document.getElementsByClassName(
        'v-tab'
      )[2].innerHTML = `HIGH SCHOOL TRACKING (${applications.length})`
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
</style>
