<!--
  App header displayed when printing an application
-->

<template>
  <header v-if="application" class="PrintHeader">
    <table>
      <tbody>
        <tr>
          <td class="col-logo">
            <img
              class="logo-light"
              src="@/assets/logo.svg"
              alt="NSW Government"
            />
            <img
              class="logo-dark"
              src="@/assets/logo-dark.svg"
              alt="NSW Government"
            />
          </td>
          <td class="col-enrolment">
            <h1 class="heading text-no-wrap">Local Enrolment</h1>
            <span>{{ schoolName }}</span>
          </td>
          <td class="col-student">
            <div class="heading">{{ fullName }}</div>
            <div v-if="isWithdrawn" class="text-no-wrap">
              WITHDRAWN (by {{ application.lastModifiedUser }} on
              {{ application.lastModifiedDatetime | moment('DD/MM/YY') }})
            </div>
            <div v-else class="text-no-wrap">
              <span v-if="srn">SRN: {{ srn }} | </span>
              <strong>{{ year }}</strong>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </header>
</template>

<script>
export default {
  name: 'AppPrintHeader',
  computed: {
    application() {
      return this.$store.getters.application
    },
    model() {
      return this.$store.getters.model
    },
    fullName() {
      let { firstName, otherName, familyName } = this.application.student
      return [firstName, otherName, familyName].join(' ')
    },
    schoolName() {
      return this.application.schoolName
    },
    srn() {
      return this.application.student.srn
    },
    year() {
      return this.model.find((row) => row.apiKey === 'scholasticYear')
        .oesDisplayValue
    },
    isWithdrawn() {
      return this.$store.getters.isWithdrawn
    }
  }
}
</script>

<style scoped lang="scss">
.PrintHeader {
  margin-bottom: 2rem;
  background-color: black;
  color: white;

  // Force background colours to print: stackoverflow.com/a/45944620
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;

  table {
    width: 100%;
    border-spacing: 1em;
  }

  tbody {
    vertical-align: top;
  }

  .heading {
    font-weight: normal;
    font-size: 1.75em;
  }

  .col-logo {
    overflow: hidden;
    width: 45px;
    max-width: 45px;
  }

  .col-student {
    padding-left: 6%;
    text-align: right;

    .heading {
      overflow-wrap: break-word;
      word-wrap: break-word;
    }
  }

  .logo-dark {
    display: none;
  }
}

// Styles for IE (hack from browserhacks.com)
@media screen and (min-width: 0\0), print and (min-width: 0\0) {
  .PrintHeader {
    margin-bottom: 2rem;
    max-width: 100vw;
    background-color: transparent;
    color: black;

    .logo {
      height: 49px;
    }

    .logo-light {
      display: none;
    }

    .logo-dark {
      display: block;
    }
  }
}
</style>
