let mockEndpoints = []
let isOfflineTesting = process.env.VUE_APP_OFFLINE_TESTING === 'true'

import Axios from 'axios'
import UTILS from '@/store/utils'
import { GENERIC_SERVER_ERROR, RESOLUTION_TYPE } from '@/constants'

var store
const appWindow = UTILS.isChildWindow() ? window.opener : window

const CUSTOM_ERROR_CODES = {
  [500]: {
    ['withdrawApplication']: {
      forceGeneric: true
    }
  }
}

if (isOfflineTesting) {
  // If offline testing we conditionally import mock data (only possible using "require")
  mockEndpoints = import('@/store/mockEndpoints')
}

// Axios Request / Response Interceptor for handling Refresh & Auth tokens.
// Axios helpers
let isRefreshing = false
let requestsAwaitingRefresh = []

function addToRefreshWaitQueue(callback) {
  requestsAwaitingRefresh.push(callback)
}
function onRefreshed(accessToken, idToken) {
  requestsAwaitingRefresh.map((callback) => callback(accessToken, idToken))
}

// Refresh token response interceptor
Axios.interceptors.response.use(
  // return a successful response with no processing
  (response) => response,
  (error) => {
    const originalRequest = error.config
    const response = error.response

    if (!response || !originalRequest) {
      return Promise.reject(error)
    }

    const { status } = response

    // Token refresh or auth related API didn't work, so user must reauthenticate.
    if (originalRequest.url.includes('access_token')) {
      store.dispatch('reauthenticateUser')
      return Promise.reject(error)
    }

    // Check if its a 401 & invoke refreshTokens action, before retrying the request
    if (status === 401) {
      if (!isRefreshing) {
        isRefreshing = true
        store
          .dispatch('refreshTokens')
          .then((wasSuccess) => {
            if (wasSuccess) {
              isRefreshing = false
              // Refreshing complete and new token set. Re-run failed requests and empty the queue.
              onRefreshed()
              requestsAwaitingRefresh = []
            }
          })
          .catch((error) => {
            // Token refresh didn't work, so reauthenticate user
            store.dispatch('reauthenticateUser')
            throw error
          })
      }

      //Add callback to queue to rerun the 401'd request with new tokens
      return new Promise((resolve) => {
        addToRefreshWaitQueue(() => {
          const idToken = window.sessionStorage.getItem('idToken')
          const accessToken = window.sessionStorage.getItem('accessToken')
          originalRequest.headers.Authorization = `Bearer ${accessToken}`
          originalRequest.headers['x-id-token'] = idToken
          resolve(Axios(originalRequest))
        })
      })
    }

    return Promise.reject(error)
  }
)

export default {
  setStore(storeInstance) {
    // This is called when the store is created to give store access
    store = storeInstance
  },

  isOfflineTesting: isOfflineTesting,

  get(endpoint, noSpinner, headers, params, ignoreErrorHandling) {
    /*
      Makes a GET request for a specified endpoint or returns mock data if the endpoint in mocked
        endpoint <string> - Required endpoint, e.g. "/applications/<id>". Will be prefixed with API url unless endpoint is already a URL ("http...")
        noSpinner <boolean> - Optional flag to prevent the spinner displaying during the call
        headers <object> - Optional headers to use instead of defaults
        ignoreErrorHandling <boolean> - Ignores the automatic error handling implemented in the response interceptor. Useful if custom error handling is required for an individual request.
    */
    return this.request({
      method: 'get',
      endpoint: endpoint,
      payload: null,
      noSpinner: noSpinner,
      headers: headers,
      params: params,
      ignoreErrorHandling
    })
  },

  post(endpoint, payload, noSpinner, headers, ignoreErrorHandling, config) {
    /*
      Makes a POST request for a specified endpoint or returns mock data if the endpoint in mocked
        endpoint <string> - Required endpoint, e.g. "/applications/<id>". Will be prefixed with API url unless endpoint is already a URL ("http...")
        payload <json> - Optional payload
        noSpinner <boolean> - Optional flag to prevent the spinner displaying during the call
        headers <object> - Optional headers to use instead of defaults
        ignoreErrorHandling <boolean> - Ignores the automatic error handling implemented in the response interceptor. Useful if custom error handling is required for an individual request.
        config - any additional axios configuration
    */
    return this.request({
      method: 'post',
      endpoint: endpoint,
      payload: payload,
      noSpinner: noSpinner,
      headers: headers,
      ignoreErrorHandling,
      config
    })
  },

  put(endpoint, payload, noSpinner, headers, ignoreErrorHandling, config) {
    /*
      Makes a PUT request for a specified endpoint or returns mock data if the endpoint in mocked
        endpoint <string> - Required endpoint, e.g. "/applications/<id>". Will be prefixed with API url unless endpoint is already a URL ("http...")
        payload <json> - Optional payload
        noSpinner <boolean> - Optional flag to prevent the spinner displaying during the call
        headers <object> - Optional headers to use instead of defaults
        ignoreErrorHandling <boolean> - Ignores the automatic error handling implemented in the response interceptor. Useful if custom error handling is required for an individual request.
        config - any additional axios configuration
    */
    return this.request({
      method: 'put',
      endpoint,
      payload,
      noSpinner,
      headers,
      ignoreErrorHandling,
      config
    })
  },

  patch(endpoint, payload, noSpinner, headers, ignoreErrorHandling, config) {
    /*
      Makes a PATCH request for a specified endpoint or returns mock data if the endpoint in mocked
        endpoint <string> - Required endpoint, e.g. "/applications/<id>". Will be prefixed with API url unless endpoint is already a URL ("http...")
        payload <json> - Optional payload
        noSpinner <boolean> - Optional flag to prevent the spinner displaying during the call
        headers <object> - Optional headers to use instead of defaults
        ignoreErrorHandling <boolean> - Ignores the automatic error handling implemented in the response interceptor. Useful if custom error handling is required for an individual request.
        config - any additional axios configuration
    */
    return this.request({
      method: 'patch',
      endpoint: endpoint,
      payload: payload,
      noSpinner: noSpinner,
      headers: headers,
      ignoreErrorHandling,
      config
    })
  },

  delete(endpoint, payload, noSpinner, headers, ignoreErrorHandling, config) {
    /*
      Makes a DELETE request for a specified endpoint or returns mock data if the endpoint in mocked
        endpoint <string> - Required endpoint, e.g. "/applications/<id>". Will be prefixed with API url unless endpoint is already a URL ("http...")
        payload <json> - Optional payload
        noSpinner <boolean> - Optional flag to prevent the spinner displaying during the call
        headers <object> - Optional headers to use instead of defaults
        ignoreErrorHandling <boolean> - Ignores the automatic error handling implemented in the response interceptor. Useful if custom error handling is required for an individual request.
        config - any additional axios configuration
    */
    return this.request({
      method: 'delete',
      endpoint: endpoint,
      payload: payload,
      noSpinner: noSpinner,
      headers: headers,
      ignoreErrorHandling,
      config
    })
  },

  request({
    method,
    endpoint,
    payload,
    noSpinner,
    headers,
    params,
    ignoreErrorHandling,
    config = {}
  }) {
    /*
      Makes a request for a specified endpoint or returns mock data if the endpoint in mocked
        method <string> - Required http verb ('get', 'post' or 'put')
        endpoint <string> - Required endpoint, e.g. "/applications/<id>". Will be prefixed with API url unless endpoint is already a URL ("http...")
        payload <json> - Optional payload
        noSpinner <boolean> - Optional flag to prevent the spinner displaying during the call
        headers <object> - Optional headers to use in addition to defaults
        params <object> - Optional params to use
        ignoreErrorHandling <boolean> - Ignores the automatic error handling implemented in the response interceptor. Useful if custom error handling is required for an inividual request.
    */

    if (headers && headers.noDefaultHeader) {
      // don't append default headers
      delete headers.noDefaultHeader
    } else {
      headers = { ...this.getHeaders(), ...headers }
    }

    this.setupAjaxErrorHandling()

    return new Promise((resolve, reject) => {
      var mockEndpoint = this.getMockEndpoint(endpoint)

      if (mockEndpoint && mockEndpoint.httpCode === 200) {
        resolve(mockEndpoint)
      } else if (mockEndpoint) {
        this.showErrorWarning({}, mockEndpoint.httpCode)
        reject(mockEndpoint)
      } else {
        if (!noSpinner) {
          store.dispatch('showSpinner')
        }

        Axios({
          method,
          url: this.resolveEndpoint(endpoint),
          data: payload,
          headers,
          params,
          ignoreErrorHandling,
          ...config
        })
          .then((response) => {
            if (!noSpinner) {
              store.dispatch('hideSpinner')
            }
            resolve(response)
          })
          .catch((error) => {
            store.dispatch('hideSpinner')
            reject(error)
          })
      }
    })
  },

  mockEndpoint(endpoint, mockData, httpCode) {
    /*
      Sets up an endpoint to return mock data
        endpoint <string> - Required endpoint, e.g. "/applications/<id>"
        mockData <json> - Required response data
        httpCode <integer> - Optional http code. Defaults to 200
    */
    mockEndpoints.push({
      endpoint: endpoint,
      data: mockData,
      httpCode: httpCode || 200
    })
  },

  getMockEndpoint(endpoint) {
    /*
      Returns a mock endpoint (if one exists) for the current request endpoint.
        endpoint <string> - Required endpoint, e.g. "/applications/<id>"

      If a mock endpoint ends in '*' (e.g. '/applications/*') it will be returned
      for any request that begins with that signature.
    */
    return mockEndpoints.find((mock) => {
      var beginsWith
      if (mock.endpoint.indexOf('*') + 1 === mock.endpoint.length) {
        beginsWith = mock.endpoint.replace('*', '')
      }
      if (
        mock.endpoint === endpoint ||
        endpoint.indexOf(beginsWith) === 0 ||
        mock.endpoint.endsWith('documentScanStatus')
      ) {
        return mock
      }
    })
  },

  resolveEndpoint(endpoint) {
    // If endpoint is full url, use it unchanged...
    if (
      endpoint.indexOf('http') === 0 ||
      endpoint.indexOf('https') === 0 ||
      endpoint.indexOf('//') === 0
    ) {
      return endpoint
    }
    // If endpoint is relative (uri), prefix with our API root...
    return process.env.VUE_APP_ROOT_API + endpoint
  },

  getHeaders() {
    return {
      'Content-Type': 'application/json',
      'X-Correlation-ID': this.getGuid(),
      Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      'x-id-token': sessionStorage.getItem('idToken')
    }
  },

  getGuid() {
    // Source:
    // https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      }
    )
  },

  setupAjaxErrorHandling() {
    // Globally handles ajax errors without need for "catch" methods
    // on every call.
    var me = this
    if (Axios.interceptors.response.handlers.length <= 1) {
      Axios.interceptors.response.use(
        function (response) {
          // Return successful responses unmodified...
          return response
        },
        function (error) {
          if (!error.config.ignoreErrorHandling) {
            me.showErrorWarning(error)
          }
          return Promise.reject(error)
        }
      )
    }
  },

  getErrorMessage(error) {
    // Returns a user-centric error message from an HTTP error object
    try {
      Object.entries(CUSTOM_ERROR_CODES[error?.response?.status]).forEach(
        (acc, [code, config]) => {
          if (acc) return acc

          if (
            error?.response?.data?.detail.includes(code) ||
            error?.response?.data?.body?.description.includes(code)
          ) {
            if (config.forceGeneric) {
              throw Error
            }
          }
        }
      )

      return (
        error?.response?.data?.detail ||
        error?.response?.data?.body?.description ||
        GENERIC_SERVER_ERROR
      )
    } catch (e) {
      return error.error || GENERIC_SERVER_ERROR
    }
  },

  showErrorWarning(error, httpCode) {
    // Alert all errors except 400s (bad requests) which should
    // be handled locally if necessary (apart from 403s)...
    var errorData
    try {
      errorData = error.response.data
    } catch (e) {
      errorData = {}
    }
    if (!httpCode) {
      try {
        httpCode = error.response.status
      } catch (e) {
        httpCode = ''
      }
    }

    if (httpCode === 403) {
      store.dispatch('showMessageBox', {
        icon: 'mdi-exclamation-thick',
        html: `<h2>Access Denied</h2><div>You do not have permission to use this application. Please speak to your school principal to arrange access.</div>`,
        textConfirm: 'Log out',
        onAlways() {
          location.href = '/#/logout'
          setTimeout(() => location.reload(), 500) // IE11 requires a reload after changing the location
        }
      })
    } else if (httpCode === 409) {
      // NOTE: 409 is used to prevent users to update an changed record - concurrent updates. As confirmed with the backend, 409 is reserved for concurrent updates, therefore the error message is hard-coded at the frontend.
      store.dispatch('showMessageBox', {
        icon: 'priority_high',
        html: `<h2>Unable to update</h2>Sorry this application has been updated by another user. To get the latest application information, please refresh this application.`
      })
    } else if (httpCode === 422) {
      // NOTE: 422 is used for requests that fail server-side business logic
      store.dispatch('showMessageBox', {
        icon: 'priority_high',
        html: `<h2>${errorData.title || 'Unable to complete request'}</h2>${
          errorData.detail || GENERIC_SERVER_ERROR
        }`
      })
    } else if (httpCode === 404) {
      // NOTE: 400 is used for requests that fail to retrieve resources
      store.dispatch('showMessageBox', {
        icon: 'priority_high',
        html: `<h2>${errorData.title || 'Not found'}</h2>${
          errorData.detail || GENERIC_SERVER_ERROR
        }`
      })
    } else if (httpCode < 400 || httpCode >= 500) {
      store.dispatch('showMessageBox', {
        icon: 'priority_high',
        html: `<h2>Unable to complete request</h2>${this.getErrorMessage(
          error
        )}`
      })
    }
  },

  removeEmptyKeys(data, includeMinusKeys) {
    // The API requires that empty values have their keys removed completely.
    // When sending to ERN, we also remove any -1 values as these are only
    // used to indicate record linking that has been resolved by the user as
    // "New record (not in ERN)". ERN will not understand -1 so these
    // ERN links will need to be removed, allowing ERN to create a new record.
    for (let key of Object.keys(data)) {
      let fieldVal = data[key]
      if (UTILS.isObject(fieldVal)) {
        this.removeEmptyKeys(fieldVal, includeMinusKeys)
      } else if (
        fieldVal === '' ||
        fieldVal === null ||
        (includeMinusKeys && fieldVal === -1)
      ) {
        delete data[key]
      }
    }
  },

  preparePutData(options) {
    // When saving the application, a number of processes need to be carried
    // out to prepare and clean up the data. Note that alertResolutions is
    // excluded from these processes.

    // options:
    // isSendToErn <boolean> - If sending to ERN, remove hidden field values & remove minus value record linking
    // isOldApplication <boolean> - If preparing unedited application data, ensure alertsFound is not changed
    options = options || {}
    var putData = UTILS.clone(
      options.isOldApplication
        ? store.state.cleanApplication
        : store.state.application
    )
    var alertResolutions = UTILS.clone(putData.alertResolutions)
    if (options.isSendToErn) {
      putData.alertsFound = null
      removeHiddenFields(putData)
      // Fix for OE-601
      // Need to add keep resolution data to application payload start
      const keepResolutions = alertResolutions?.filter(
        (resolution) => resolution.type === RESOLUTION_TYPE.KEEP
      )
      keepResolutions.forEach((resolution) => {
        if (resolution.id) {
          const [, parentKey, third] = resolution.id.split(/>|\./)
          const childKey = third.replace(/\[\d+\]/, '')
          eval(`putData.${parentKey}.${childKey}`).push(
            resolution.originalErnValue
          )
        }
      })
      // Need to add keep resolution data to application payload start
    } else if (!options.isOldApplication) {
      putData.alertsFound = store.getters.totalAlerts > 0
    }
    this.removeEmptyKeys(putData, options.isSendToErn)
    putData.alertResolutions = alertResolutions // Reinstate alert resolutions AFTER removing empty keys (we don't want them removed from alertResolutions)
    return putData

    function removeHiddenFields(data) {
      // Some fields are hidden based on selections made in
      // other fields. In the event that these fields still
      // contain data, we need to remove that data when saving.
      if (data) {
        store.getters.model.forEach((modelRow) => {
          if (
            modelRow.isHidden &&
            modelRow?.apiKey !== 'permissionToOnlineServices'
          ) {
            try {
              eval(`delete data.${modelRow.apiKey}`)
            } catch (e) {
              // Fix for FUS-467
              // Above delete is failing to access non-existant array
              // data.preschools[0].postCode will fail if data.preschools is undefined
            }
          }
        })
      }
    }
  },

  decodeToken(token) {
    const tokenBody = token.split('.')[1]
    return JSON.parse(atob(tokenBody))
  },

  getToken(tokenName) {
    // Returns a decoded token object for the specified token, or
    // if we're in offline mode returns a mock token with the combined
    // mock values of all tokens.

    if (isOfflineTesting) {
      return {
        givenName: 'James',
        sn: 'Test',
        idToken: 'tokenId',
        userID: 'userId'
      }
    }
    try {
      let token = appWindow.sessionStorage.getItem(tokenName)
      return this.decodeToken(token)
    } catch (e) {
      return null
    }
  },

  clearTokens() {
    'accessToken,idToken,tokenExpiry,refreshToken,si.y67tRoundsAck'
      .split(',')
      .forEach((val) => sessionStorage.removeItem(val))
  },

  getSignedURLPayload(fileKey, previewMode) {
    let fileInfo = fileKey.split('/')
    let applicationId = fileInfo[0]
    let fileCategory = fileInfo[1]
    let fileName = fileInfo[3] ? `${fileInfo[2]}/${fileInfo[3]}` : fileInfo[2]

    let filePayload = {
      applicationId: applicationId,
      category: fileCategory,
      filename: fileName,
      action: 'DOWNLOAD',
      previewMode: previewMode
    }
    return filePayload
  }
}
