<!--
  This template displays content for a "collection" field (a field with type "COLLECTION").
  A collection field represents an array of records, and contains its own set of fields to
  be displayed for each record.
-->
<template>
  <div
    :data-id="modelRow.id"
    :class="
      'FieldCollection' +
      (modelRow.isConflict && !isPrintPreview ? ' isCollectionConflict' : '')
    "
  >
    <div v-if="isPlaceholderVisible" class="placeholderTitle">
      {{ modelRow.field.placeholderTitle }}
    </div>

    <!-- Collection records... -->
    <div v-if="getRecords.length > 0">
      <div
        v-for="recordModelRow in getRecords"
        :data-id="recordModelRow.id"
        :key="recordModelRow.id + recordRevisionCount"
        :class="
          (withWhiteBox(recordModelRow)
            ? `collectionItemFields ${recordModelRow.section.id}`
            : 'ma-4') + getBackgroundColorClass(recordModelRow)
        "
      >
        <RecordHeading
          v-if="recordModelRow.field.heading"
          :model-row="recordModelRow"
          @remove="remove(recordModelRow)"
        />
        <!-- Collection record fields... -->
        <div
          v-for="recordFieldModelRow in getChildren(recordModelRow)"
          :key="recordFieldModelRow.id"
          :class="
            (isFullWidthRow(recordFieldModelRow) ? '' : 'columnLayout') +
            getFieldWidthClass(recordFieldModelRow)
          "
        >
          <br v-if="recordFieldModelRow.type == 'LINEBREAK'" />
          <FieldCollection
            v-else-if="recordFieldModelRow.type == 'COLLECTION'"
            :model-row="recordFieldModelRow"
          />
          <FieldGroup
            v-else-if="recordFieldModelRow.type == 'GROUP'"
            :model-row="recordFieldModelRow"
          />
          <FieldHeading
            v-else-if="recordFieldModelRow.type == 'HEADING'"
            :model-row="recordFieldModelRow"
          />
          <FieldCustom
            v-else-if="recordFieldModelRow.type == 'CUSTOM'"
            :model-row="recordFieldModelRow"
          />

          <AppFileEditor
            v-else-if="recordFieldModelRow.type === 'FILE_EDITOR'"
            :label="recordFieldModelRow.label"
            :value="recordFieldModelRow.oesValue"
            :handle-view="recordFieldModelRow.field.handleView"
            :can-view="recordFieldModelRow.field.canView"
            :status="recordFieldModelRow.field.status"
          />

          <Field
            v-else-if="
              isEditing &&
              !recordFieldModelRow.isHidden &&
              !recordFieldModelRow.isReadOnly &&
              !recordFieldModelRow.isResolved
            "
            :model-row="recordFieldModelRow"
          />
          <FieldReadOnly v-else :model-row="recordFieldModelRow" />
        </div>

        <!-- If record has an alert... -->
        <FieldAlert :model-row="recordModelRow" class="alert" />
        <RecordActions
          v-if="recordModelRow.field.showKeepRemoveOptions"
          :model-row="recordModelRow"
          @remove="remove(recordModelRow)"
        />
      </div>
    </div>
    <div class="collectionItemFields pa-3" v-else-if="showNone">(None)</div>

    <div
      :class="'collectionFooter'"
      v-if="
        !modelRow.isReadOnly &&
        !isPrintPreview &&
        !isSentToErn &&
        (modelRow.isConflict ||
          modelRow.warningMessage ||
          modelRow.field.newRecordTemplate) &&
        (modelRow.field.maxItems
          ? getRecords.length < modelRow.field.maxItems
          : true)
      "
    >
      <!-- If collection has an alert... -->
      <FieldAlert :model-row="modelRow" />

      <!-- If collection has an Add button... -->
      <button
        v-if="modelRow.field.newRecordTemplate && !modelRow.isResolved"
        type="button"
        class="add unstyled"
        ref="addRecordButton"
        @click="add()"
        :data-title="modelRow.addLabel"
      >
        <font-awesome-icon focusable="false" icon="plus" class="icon" />
        <span>{{ modelRow.addLabel }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import FieldHeading from '@/components/form/FieldHeading.vue'
import FieldGroup from '@/components/form/FieldGroup.vue'
import RecordHeading from '@/components/form/RecordHeading.vue'
import RecordActions from '@/components/form/RecordActions.vue'
import Field from '@/components/form/Field.vue'
import FieldReadOnly from '@/components/form/FieldReadOnly.vue'
import FieldAlert from '@/components/form/FieldAlert.vue'
import fieldHelperMixin from '@/helpers/fieldHelperMixin'
import FieldCustom from '@/components/form/FieldCustom.vue'
import AppFileEditor from '@/components/app/AppFileEditor.vue'
import { CONFLICT_ALERTING, FILE_STATUSES } from '@/constants'

export default {
  name: 'FieldCollection',
  components: {
    FieldHeading,
    RecordHeading,
    RecordActions,
    FieldGroup,
    Field,
    FieldReadOnly,
    FieldAlert,
    FieldCustom,
    AppFileEditor
  },
  mixins: [fieldHelperMixin],
  props: {
    modelRow: {
      type: Object
    }
  },
  data() {
    return {
      recordRevisionCount: 0 // Used to correctly re-render records after a record is removed (due to the fact that we use index-based ids)
    }
  },
  computed: {
    getRecords() {
      let records = this.getChildren(this.modelRow)
      if (this.modelRow.field.conflictAlerting === CONFLICT_ALERTING.LIST) {
        // Currently we are not displaying unmatched ERN records in LIST-type
        // collections, so we filter them out...
        return records.filter((record) => !record.isUnmatchedErnRecord)
      }
      return records
    },
    isPlaceholderVisible() {
      // Show placeholder title if defined and if no OES or ERN records in the collection
      return (
        this.modelRow.field.placeholderTitle &&
        !this.modelRow.oesValue.length &&
        !(this.modelRow.ernValue && this.modelRow.ernValue.length)
      )
    },
    showNone() {
      return this.modelRow.field.showNone
    }
  },
  methods: {
    getBackgroundColorClass(recordModelRow) {
      var classes = ''
      if (this.isPrintPreview || this.isSentToErn) {
        return ''
      }
      if (recordModelRow.isReadOnly) {
        classes += ' isReadOnly'
      }
      if (recordModelRow.isConflict) {
        classes += ' isRecordConflict'
      }
      return classes
    },
    isFullWidthRow(modelRow) {
      return (
        modelRow.type == 'HEADING' ||
        modelRow.type == 'COLLECTION' ||
        modelRow.type == 'GROUP'
      )
    },
    withWhiteBox(modelRow) {
      return (
        modelRow.id.indexOf('supportingDocuments') < 0 ||
        (modelRow.id.indexOf('supportingDocuments') >= 0 &&
          modelRow.oesValue.status === FILE_STATUSES.SAFE)
      )
    },
    add() {
      // Adds a new empty item into a collection
      let records = this.modelRow.oesValue
      let newRecord = this.modelRow.field.newRecordTemplate()
      let newRecordNumber = getDummyRecordNumber(this.modelRow)

      this.$store.dispatch('editApplication')

      if (newRecordNumber) {
        newRecord[this.modelRow.field.oesRecordNumberField] = newRecordNumber
      }
      records.push(newRecord)
      this.$store.dispatch('set', [
        'application.' + this.modelRow.apiKey,
        this.setCollectionOrder(this.modelRow.field, records)
      ])
      this.$store.dispatch('refreshModel')
      let newRecordModelRow = this.model.find(
        (row) => row.id === `${this.modelRow.id}[${records.length - 1}]`
      )
      this.$store.dispatch('setFocus', this.getFirstField(newRecordModelRow))

      function getDummyRecordNumber(modelRow) {
        // Scans collection for any other dummy record numbers (negative numbers)
        // and returns the lowest minus 1.
        var oesRecordNumberField = modelRow.field.oesRecordNumberField
        if (modelRow.field.useDummyRecordNumbers && oesRecordNumberField) {
          var lowestRecordNumber = 0
          records.forEach((record) => {
            var recordNo = Number(record[oesRecordNumberField])
            if (recordNo < lowestRecordNumber) {
              lowestRecordNumber = recordNo
            }
          })
          return String(lowestRecordNumber - 1)
        }
      }
    },

    remove(recordModelRow) {
      var me = this

      if (recordModelRow.field.validateRemove) {
        const validationMessage = recordModelRow.field.validateRemove(
          recordModelRow.oesValue,
          this.application
        )

        if (typeof validationMessage === 'string') {
          this.$store.dispatch('showMessageBox', {
            textConfirm: 'OK',
            icon: 'mdi-exclamation-thick',
            message: validationMessage
          })
          return
        }
      }

      this.$store.dispatch('showMessageBox', {
        textConfirm: 'Remove',
        html: `<h2>Remove ${recordModelRow.label}?</h2>Changes will be saved when you update this application.</div>`,
        onConfirm() {
          let records = recordModelRow.parent.oesValue
          records.splice(recordModelRow.recordIndex, 1)
          me.$store.dispatch('setFieldValue', [recordModelRow.field, records])
          me.updateAlertResolutionsAfterRemovingItem(recordModelRow.recordIndex)
          if (recordModelRow.field.onRemove) {
            // If collection has an onRemove callback, allow it to modify our application data
            var applicationData = me.application
            recordModelRow.field.onRemove(
              recordModelRow.oesValue,
              applicationData
            )
            me.$store.dispatch('set', ['application', applicationData])
          }
          me.$store.dispatch('refreshModel')

          /*
            Vue for-loops need a unique "key" attribute which helps Vue know when
            to re-render the records. Our best unique key to use is our model row
            id, which is an index-based record/field identifier, e.g.:

              medical>doctorDetails[1].doctorPhoneNumber

            The problem is that if we remove a record, any subsequent record will
            now have the id of the removed record (because the id relates to index
            position). Because of this Vue will not detect the change correctly and
            will continue to display the record that was removed (but not the record
            after it).

            By updating recordRevisionCount when removing a record, we force Vue to
            re-render the records correctly. NOTE: I also tried adding Math.random()
            into the for-loop key, but this caused record fields to lose focus when
            modifying a value.
          */
          me.recordRevisionCount++
        }
      })
    },

    setCollectionOrder(field, collection) {
      if (field.orderBy) {
        collection.forEach((item, index) => {
          item[field.orderBy] = index
        })
      }
      return collection
    },

    updateAlertResolutionsAfterRemovingItem(indexRemoved) {
      /*
        Alert resolutions use their id to reference the field they relate to, e.g.
        id: "medical>doctorDetails[1].doctorPhoneNumber"

        The only time this id can get out of sync is when a record is removed. So
        whenever a record is removed, any conflict resolutions made within that
        record must also be removed. In addition, any subsequent records must
        have their alert resolutions reindexed, otherwise they will point to the
        wrong record.

        For example, let's say we have resolved the phone number on our second doctor:

          medical>doctorDetails[1].doctorPhoneNumber

        If we delete the first doctor, our alert resolution id must change to:

          medical>doctorDetails[0].doctorPhoneNumber
      */

      var updatedAlertResolutions = []
      var searchFragment = this.modelRow.id + '[' // e.g. "medical>doctorDetails["
      this.application.alertResolutions.forEach((alertRes) => {
        // If alert resolution relates to the current collection...
        if (alertRes.id.indexOf(searchFragment) === 0) {
          var alertResIndex = getIndex(alertRes.id)
          if (alertResIndex > indexRemoved) {
            alertRes.id = reindexAlertResolutionId(
              alertRes.id,
              alertResIndex - 1
            )
          }
          if (alertResIndex !== indexRemoved) {
            updatedAlertResolutions.push(alertRes)
          }
        } else {
          updatedAlertResolutions.push(alertRes)
        }
      })
      this.$store.dispatch('set', [
        'application.alertResolutions',
        updatedAlertResolutions
      ])

      function getIndex(alertResId) {
        // If the current collection is "doctorDetails" and the alert resolution has an id of
        // "medical>doctorDetails[1].doctorPhoneNumber", then getIndex() would return 1
        var idxStart = searchFragment.length
        var idxEnd = idxStart + alertResId.substr(idxStart).indexOf(']')
        return Number(alertResId.substring(idxStart, idxEnd))
      }

      function reindexAlertResolutionId(alertResId, newIndex) {
        // Replaces the index for the current collection within an alert resolution's id, e.g.
        // "medical>doctorDetails[ <newIndex> ].doctorPhoneNumber"
        var idxStart = searchFragment.length
        var idxEnd = idxStart + alertResId.substr(idxStart).indexOf(']')
        return searchFragment + newIndex + alertResId.substr(idxEnd)
      }
    }
  },

  watch: {
    focusFieldId() {
      // If the focus is being set to this collection, set focus to
      // 'addRecordButton' as it is the only interactive control available
      // on a collection.
      if (this.modelRow.id === this.focusFieldId) {
        this.$refs.addRecordButton.focus()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.FieldCollection {
  padding-bottom: 0.5rem;

  &.isCollectionConflict,
  .isRecordConflict {
    background-color: $color-red-background;
  }

  .isRecordConflict {
    margin: 0.5rem 0;
  }

  .isReadOnly {
    background-color: $color-disabled-section;
    margin: 0.5rem 0;
  }

  .columnLayout {
    display: inline-block;
    vertical-align: top;
    width: 33.33%; // Currently giving a three column layout - TODO: Make responsive
    &.width66 {
      width: 66.66%;
    }
    &.width100 {
      width: 100%;
    }
    & > * {
      width: 100%;
    }
  }
  .collectionFooter {
    padding: 0 $content-padding;
    .FieldAlert {
      padding: 0;
      padding: 0.5em 0;
    }
  }
  .add {
    color: $color-secondary;
    text-decoration: underline;
    font-size: 0.9rem;
    margin: 0.5em 0;
    .icon {
      width: 1em;
      height: 1em;
      color: white;
      background-color: $color-secondary;
      border-radius: 1em;
      padding: 0.15em;
      font-size: 1.1rem;
      vertical-align: middle;
      margin-right: 0.25em;
    }
    span {
      vertical-align: middle;
    }
  }

  .alert {
    padding: 0 1em 1em;
  }

  .collectionItemFields {
    box-shadow: 0 1px 3px #c5c5c5;
    margin: 1rem;
    .collectionItemFields {
      // Child records use a flat style
      box-shadow: none;
      border: 1px solid #e9e9e9;
    }
  }
  .collectionItemFields.ooa {
    box-shadow: none;
    margin: 0;
    width: 33.33%;
    display: inline-block;
  }
  .collectionItemFields.ooa.isReadOnly {
    background-color: transparent;
  }
  .placeholderTitle {
    font-weight: bold;
    padding: 1rem;
  }
}
.collectionItemFields.isReadOnly:is(.family, .other, .emergency):has(
    [data-unmatched-kept],
    [data-unmatched-removed]
  ) {
  background-color: white;
}
::v-deep
  .collectionItemFields.isReadOnly:is(.family, .other, .emergency):has(
    [data-unmatched-removed]
  )
  .fieldContent {
  text-decoration: line-through;
  opacity: 0.6;
}
</style>
