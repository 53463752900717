<!-- 
  Provides a spinner covering the UI. Useful for blocking
  the UI while important data is loading. Uses a Vue transition
  for fading in and out. 
-->

<template>
  <div aria-live="assertive">
    <transition name="fade">
      <div
        v-if="showSpinner"
        class="AppSpinner d-flex flex-column"
        role="alert"
        aria-busy="true"
      >
        <font-awesome-icon focusable="false" class="icon" icon="circle-notch" />
        <div class="forScreenReaderOnly">Loading data. Please wait.</div>
        <v-sheet
          v-if="showSheet"
          class="AppSheet pa-8"
          color="white"
          elevation="12"
          rounded
          width="405"
          ><div>
            Please wait for this list to refresh. <br /><br />
            Do not refresh the page or close your browser.
          </div></v-sheet
        >
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'AppSpinner',
  computed: {
    showSpinner() {
      return this.$store.getters.isSpinner
    },
    showSheet() {
      return this.$store.getters.isSheet
    }
  }
}
</script>

<style scoped lang="scss">
.AppSpinner {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(87, 101, 119, 0.28); // Shades the background slightly
  color: white;
  cursor: default;
  z-index: 10000;

  .icon {
    font-size: 8rem;
    animation: spin 3s infinite linear;
    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  }
  .AppSheet {
    z-index: 10001;
    margin-top: 64px;
  }
}

// Classes auto generated by Vue transition
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
