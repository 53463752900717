import { APPLICATION_OFFER_TYPE_NAME, ENROLMENT_TYPE } from '@/constants'

export function getEnrolmentType(application) {
  // Check enrolment type for SHS_OFFER and SCT_OFFER
  if (application.enrolmentType) {
    if (application.enrolmentType === ENROLMENT_TYPE.SHS) {
      return APPLICATION_OFFER_TYPE_NAME.SHS_OFFER
    } else if (application.enrolmentType === ENROLMENT_TYPE.SCT) {
      return APPLICATION_OFFER_TYPE_NAME.SCT_OFFER
    } else if (application.enrolmentType === ENROLMENT_TYPE.SCE) {
      return APPLICATION_OFFER_TYPE_NAME.SCE_OFFER
    }
  }
  // Consolidate conditions for OUT_OF_AREA_OFFER
  if (
    (application.ooaApplicationNo && application.ooaApplicationNo.length > 0) ||
    (application.ooaApplicationID && application.ooaApplicationID.length > 0)
  ) {
    return APPLICATION_OFFER_TYPE_NAME.OUT_OF_AREA_OFFER
  }
  // Condition for Y67T_OFFER
  if (application.eoiID && application.eoiID.length > 0) {
    return APPLICATION_OFFER_TYPE_NAME.Y67T_OFFER
  }
  // Condition for SHS_OFFER based on shsOfferId
  if (application.shsOfferId && application.shsOfferId.length > 0) {
    return APPLICATION_OFFER_TYPE_NAME.SHS_OFFER
  }
  // Default case for LOCAL_AREA_OFFER
  return APPLICATION_OFFER_TYPE_NAME.LOCAL_AREA_OFFER
}
