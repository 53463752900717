// See README.md for more info about field configuration

import {
  FIELD_TYPE,
  CONFLICT_ALERTING,
  DATASET,
  DATE_FORMAT,
  MATCH
} from '@/constants'
import {
  getContactText,
  getContactNumberFields,
  isParentCarerMatch,
  getParentCarerSummaryText,
  statementOfAccountChangeHandler
} from './helpers'
import UTILS from '@/store/utils'
import store from '@/store/store'
import moment from 'moment'
import validators from './validators'

export default [
  // PARENT CARERS -------------------------------------------
  {
    apiKey: 'parentCarers',
    type: FIELD_TYPE.COLLECTION,
    filter: {
      apiKey: 'isEnrolmentOwner',
      value: false
    },
    placeholderTitle: 'No other family details available',
    heading(parentCarer) {
      return `Other parent/carer: ${parentCarer.parentCarerTitle} ${parentCarer.parentCarerGivenName} ${parentCarer.parentCarerFamilyName}`
    },
    conflictAlerting: CONFLICT_ALERTING.AB,
    ernMatch(oes, ern) {
      return isParentCarerMatch(oes, ern)
    },
    ernRecordNumberField: 'ernParentRecordNo',
    oesRecordNumberField: 'parentCarerRecordNo',
    summaryText(record) {
      return getParentCarerSummaryText(record)
    },
    fields(apiKeyPrefix, parent) {
      /**/
      const isReolvedAsList = UTILS.isOtherParentContactDetailsResolved(
        store.state.application,
        parent
      )

      return [
        {
          apiKey: apiKeyPrefix + 'parentCarerTitle',
          label: 'Title',
          required: false,
          type: FIELD_TYPE.DROPLIST,
          dataset: DATASET.TITLE
        },
        {
          apiKey: apiKeyPrefix + 'parentCarerGenderCode',
          label: 'Gender',
          required: false,
          type: FIELD_TYPE.RADIO,
          options: [
            { text: 'Male', value: 'M' },
            { text: 'Female', value: 'F' }
          ]
        },
        {
          apiKey: apiKeyPrefix + 'parentCarerRelation',
          label: 'Relationship to student',
          required: true,
          type: FIELD_TYPE.DROPLIST,
          dataset: DATASET.RELATIONSHIP
        },
        {
          apiKey: apiKeyPrefix + 'parentCarerFamilyName',
          label: 'Family name',
          required: true,
          type: FIELD_TYPE.NAME
        },
        {
          apiKey: apiKeyPrefix + 'parentCarerGivenName',
          label: 'Given name',
          required: true,
          type: FIELD_TYPE.NAME
        },
        {
          apiKey: apiKeyPrefix + 'isParentCarerAboriOrTorres',
          label: 'Aboriginality',
          required: true,
          type: FIELD_TYPE.DROPLIST,
          dataset: DATASET.ABORIGINALITY
        },
        {
          label: 'Statement of account',
          type: FIELD_TYPE.HEADING
        },
        {
          apiKey: apiKeyPrefix + 'statementOfAccount',
          label: 'Statement of account',
          required: true,
          type: FIELD_TYPE.DROPLIST,
          dataset: DATASET.STATEMENT_OF_ACCOUNT,
          validation: validators.statementAccountValidator,
          onChange(val, store) {
            statementOfAccountChangeHandler(val, store, parent)
          }
        },
        {
          label: `Occupation`,
          type: FIELD_TYPE.HEADING
        },

        {
          apiKey: apiKeyPrefix + 'parentCarerOccupationName',
          label: 'Occupation',
          validation: validators.occupationNameValidator,
          maxlength: 132,
          displayfieldAlerting: true,
          noConflictAlerting: true // for details see FUS-683, we don't want to resolve conflict for occupation
        },

        {
          apiKey: apiKeyPrefix + 'parentCarerOccupationGroup',
          label: 'Occupation group',
          type: FIELD_TYPE.DROPLIST,
          dataset: DATASET.OCCUPATION_GROUP,
          displayfieldAlerting: true,
          noConflictAlerting: true // for details see FUS-683, we don't want to resolve conflict for occupation
        },
        {
          label: `Education`,
          type: FIELD_TYPE.HEADING
        },
        {
          apiKey: apiKeyPrefix + 'parentCarerSchoolEducationCode',
          label: 'Highest level of schooling completed',
          type: FIELD_TYPE.DROPLIST,
          dataset: DATASET.SCHOOL_EDUCATION,
          displayfieldAlerting: true,
          noConflictAlerting: true // for details see FUS-683, we don't want to resolve conflict for education
        },
        {
          apiKey: apiKeyPrefix + 'parentCarerEducationQualificationCode',
          label: 'Highest qualification completed',
          type: FIELD_TYPE.DROPLIST,
          dataset: DATASET.TERTIARY_EDUCATION,
          displayfieldAlerting: true,
          noConflictAlerting: true // for details see FUS-683, we don't want to resolve conflict for education
        },

        // PARENT CARER CONTACT NUMBERS -------------------------------------------
        {
          apiKey: apiKeyPrefix + 'contactDetails',
          type: FIELD_TYPE.COLLECTION,
          conflictAlerting: isReolvedAsList
            ? CONFLICT_ALERTING.LIST
            : CONFLICT_ALERTING.AB_GROUP_CONTACT,
          ernRecordNumberField: 'ernContactDetailRecordNo',
          oesRecordNumberField: 'contactDetailRecordNo',
          showKeepRemoveOptions: !isReolvedAsList,
          heading(contactItem) {
            return getContactText(contactItem)
          },
          placeholderTitle: 'Contact details',
          orderBy: 'priority',
          addLabel: 'Add phone/email',
          newRecordTemplate() {
            return {
              contactType: '',
              contactValue: '',
              comments: ''
            }
          },
          ernMatch(oes, ern) {
            return UTILS.isMatch(oes, ern, {
              contactValue: MATCH.FUZZY_CONTACT
            })
          },
          fields(apiKeyPrefix, contactItem) {
            return getContactNumberFields(
              this.apiKey,
              contactItem,
              apiKeyPrefix,
              true,
              null,
              null,
              parent
            )
          }
        },

        {
          type: FIELD_TYPE.HEADING,
          label: 'Residential details'
        },

        {
          apiKey: apiKeyPrefix + 'residentialAddress',
          label: 'Residential address',
          required: false,
          showCountry: true,
          type: FIELD_TYPE.ADDRESS
        },
        {
          apiKey: apiKeyPrefix + 'residentialAddress.startDate',
          label: 'Start date',
          required: false,
          type: FIELD_TYPE.DATE,
          noConflictAlerting: true, // Prevents start & end dates triggering conflicts
          // eslint-disable-next-line no-unused-vars
          readOnly(application) {
            const residentialAddressLine1 = eval(
              `application.${apiKeyPrefix}residentialAddress.addressLine1`
            )
            return !!residentialAddressLine1
          }
        },
        {
          apiKey: apiKeyPrefix + 'residentialAddress.endDate',
          label: 'End date',
          required: false,
          type: FIELD_TYPE.DATE,
          specialYears: ['', '2999'],
          noConflictAlerting: true, // Prevents start & end dates triggering conflicts
          validation(endDate, application) {
            if (application) {
              const startDate = eval(
                `application.${apiKeyPrefix}residentialAddress.startDate`
              )
              if (
                startDate &&
                endDate &&
                moment(endDate, DATE_FORMAT) < moment(startDate, DATE_FORMAT)
              ) {
                return 'Cannot fall before start date'
              }
            }
          },
          // eslint-disable-next-line no-unused-vars
          readOnly(application) {
            const residentialAddressLine1 = eval(
              `application.${apiKeyPrefix}residentialAddress.addressLine1`
            )
            return !!residentialAddressLine1
          }
        },

        {
          apiKey: apiKeyPrefix + 'doesStudentLivesWithParentSometimes',
          label:
            'Student sometimes resides at this address (shared responsibility)?',
          required: false,
          type: FIELD_TYPE.RADIO,
          options: [
            { text: 'Yes', value: true },
            { text: 'No', value: false }
          ]
        },

        {
          type: FIELD_TYPE.CUSTOM,
          component: 'InfoPanel',
          componentProps: {
            content: `<span style="line-height: 24px"><b>Note: </b>Start and end dates can only be edited in ERN<span>`,
            isAlertMessage: true
          },
          colspan: 3,
          readOnly: true
        },
        {
          type: FIELD_TYPE.HEADING,
          label: 'Correspondence details'
        },

        {
          apiKey: apiKeyPrefix + 'correspondenceAddress',
          label: 'Correspondence address',
          required: false,
          showCountry: true,
          type: FIELD_TYPE.ADDRESS,
          hasPoBox: true
        },
        {
          apiKey: apiKeyPrefix + 'correspondenceAddress.startDate',
          label: 'Start date',
          required: false,
          type: FIELD_TYPE.DATE,
          noConflictAlerting: true, // Prevents start & end dates triggering conflicts
          // eslint-disable-next-line no-unused-vars
          readOnly(application) {
            const correspondenceAddressLine1 = eval(
              `application.${apiKeyPrefix}correspondenceAddress.addressLine1`
            )
            return !!correspondenceAddressLine1
          }
        },
        {
          apiKey: apiKeyPrefix + 'correspondenceAddress.endDate',
          label: 'End date',
          required: false,
          type: FIELD_TYPE.DATE,
          specialYears: ['', '2999'],
          noConflictAlerting: true, // Prevents start & end dates triggering conflicts
          validation(endDate, application) {
            if (application) {
              var startDate = eval(
                `application.${apiKeyPrefix}correspondenceAddress.startDate`
              )
              if (
                startDate &&
                endDate &&
                moment(endDate, DATE_FORMAT) < moment(startDate, DATE_FORMAT)
              ) {
                return 'Cannot fall before start date'
              }
            }
          },
          // eslint-disable-next-line no-unused-vars
          readOnly(application) {
            const correspondenceAddressLine1 = eval(
              `application.${apiKeyPrefix}correspondenceAddress.addressLine1`
            )
            return !!correspondenceAddressLine1
          }
        }
      ]
    }
  }
]
