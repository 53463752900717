import {
  FIELD_TYPE,
  MATCH,
  DATASET,
  STATEMENT_OF_ACCOUNT_TYPE
} from '@/constants'
import UTILS from '@/store/utils'
import store from '@/store/store'
import { STATES } from '@/applicationDefinition/droplistData/states'

import {
  LEARNING_ADJUSTMENT_OPTIONS,
  DISABILITY_OPTIONS
} from './specialFields'
import _get from 'lodash/get'

export function getContactText(contact) {
  var type = store.getters.lookupCode(DATASET.CONTACT_TYPE, contact.contactType)
  return type || 'Contact details'
}

// todo: refactor out
export function getOoaContactOptions() {
  return store.getters.ooaContactOptions
}

export function getParentCarerSummaryText(record) {
  var relationship = store.getters.lookupCode(
    DATASET.RELATIONSHIP,
    record.parentCarerRelation
  )
  return `${record.parentCarerTitle} ${record.parentCarerGivenName} ${record.parentCarerFamilyName}, ${relationship}`
}

export function isParentCarerMatch(oes, ern) {
  let isNameMatch = UTILS.isMatch(oes, ern, {
    parentCarerGivenName: MATCH.FUZZY,
    parentCarerFamilyName: MATCH.FUZZY
  })
  // Link if mum/dad relationship matches...
  if (oes.parentCarerRelation === 'MUM' || oes.parentCarerRelation === 'DAD') {
    return oes.parentCarerRelation === ern.parentCarerRelation && isNameMatch
  }
  // Otherwise link if name matches
  return isNameMatch
}

export function isAddressPopulated(address) {
  return (
    address.addressLine1 ||
    address.addressLine2 ||
    address.suburbName ||
    address.stateCode ||
    address.postCode ||
    address.countryCode
  )
}

export function isAustralianAddress(address) {
  return address.countryCode === 'AUS'
}

export function getContactNumberFields(
  collectionApiKey,
  contactItem,
  apiKeyPrefix,
  isEmergencyContact,
  readOnly,
  parent
) {
  let contactTypes = isEmergencyContact
    ? DATASET.EMERGENCY_CONTACT_TYPE
    : DATASET.CONTACT_TYPE
  let isEmailWithParentId =
    parent && !!parent.parentId && contactItem.contactType === FIELD_TYPE.EMAIL
  return [
    {
      apiKey: apiKeyPrefix + 'contactType',
      label: 'Type',
      type: FIELD_TYPE.DROPLIST,
      required: true,
      dataset: contactTypes,
      readOnly: !!readOnly || isEmailWithParentId,
      fieldConflictAlerting: false
    },
    {
      apiKey: apiKeyPrefix + 'contactValue',
      label: getContactText(contactItem),
      required: true,
      type: getInputType(contactItem.contactType),
      validation(value, application) {
        // Validates for duplicate contact numbers/emails
        // NOTE: "if (application)" is only required to satisfy eslint (otherwise
        // it will think "application" is unused because it is in an eval)
        if (application) {
          var contacts = eval(`application.${collectionApiKey}`)
          if (
            contacts.find(
              (c) =>
                contactItem.contactType &&
                c.contactDetailRecordNo !== contactItem.contactDetailRecordNo &&
                c.contactType === contactItem.contactType &&
                c.contactValue === contactItem.contactValue
            )
          ) {
            return `This contact must not be used twice`
          }
        }
      },
      readOnly: !!readOnly || isEmailWithParentId
    },
    {
      apiKey: apiKeyPrefix + 'comments',
      label: 'Comments',
      required: false,
      maxlength: 50,
      visible() {
        return isPhoneContact(contactItem.contactType)
      },
      readOnly: !!readOnly,
      fieldConflictAlerting: false
    }
  ]

  function getInputType(type) {
    if (type === 'EMAIL') return FIELD_TYPE.EMAIL
    if (isPhoneContact(type)) return FIELD_TYPE.PHONE
    return ''
  }

  function isPhoneContact(type) {
    var phoneTypes = ['PHDAY', 'PHMOB', 'PHTXT', 'PHHOM', 'PHWRK', 'PHSIL']
    return phoneTypes.indexOf(type) >= 0
  }
}

export function statementOfAccountChangeHandler(val, store, parent) {
  store.state.application.parentCarers.map((p) => {
    if (
      val === STATEMENT_OF_ACCOUNT_TYPE.FIR &&
      p.parentCarerRecordNo !== parent.parentCarerRecordNo &&
      p.statementOfAccount === STATEMENT_OF_ACCOUNT_TYPE.FIR
    ) {
      p.statementOfAccount = STATEMENT_OF_ACCOUNT_TYPE.FII
    }
    return p
  })
}

export function isSupportForLearningRequired(application) {
  if (
    application.hasPrevLearningSupportPlan ||
    application.areThereModifiStratUsedHome
  ) {
    return true
  }

  const hasSelection = (options) => {
    const selections = options.reduce((acc, { apiKey }) => {
      if (apiKey && _get(application, apiKey, false)) {
        acc.push(apiKey)
      }
      return acc
    }, [])
    return selections.length > 0
  }

  return (
    hasSelection(LEARNING_ADJUSTMENT_OPTIONS) ||
    hasSelection(DISABILITY_OPTIONS)
  )
}

export function getAddressString(value) {
  try {
    let state = STATES.find((state) => state.value === value.stateCode)
    let country = store.getters.lookupCode(DATASET.COUNTRIES, value.countryCode)
    let parts = [value.addressLine1, value.addressLine2]
    if (value.countryCode === 'AUS') {
      parts.push(value.suburbName)
      parts.push(state ? state.text : value.stateCode)
      parts.push(value.postCode)
    }
    if (value.countryCode != 'AUS') {
      parts.push(country)
    }

    return parts.filter((part) => part).join(' ')
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
  }
}
