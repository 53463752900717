import { DATASET, SCHOOL_CAPS, SCHOOL_SUBTYPE } from '@/constants'
import basePNRApp from '@/pnr-application-base'
import baseEOIApp from '@/eoi-application-base'
import API from '@/store/apiUtils'
import CONFLICT from '@/helpers/conflictHelper'
import store from '@/store/store'
import UTILS from '@/store/utils'
import { converToISODate } from '@/helpers/date'
import Vue from 'vue'

// The `calendar_year` from the SQL DB response will often not match the actual
// year we want to filter on. In the DB there can be multiple catchments for a school
// that differ based on `calendar_year`. The `calendar_year` can either be the an actual year
// (e.g. 2020) or '0' to indicate any future year beyond any actual years specified.
// e.g. for a given school, if there are for calendar_year entries: 2020, 2021 and 0,
// then 0 would signify any years from 2022 onwards.
// There may also be schools without a 0 calendar_year entry, e.g. just 2020 and 2021. In this case
// any future year would be based on the latest specified year (i.e. 2021).
// The below function turns an actual year into its equivalent DB calendar_year for a given school,
// based on all the calendar_years defined for the school.
const actualYearToDBYear = (allDBYearsForSchool, actualYear) => {
  const sortedYears = [...allDBYearsForSchool].sort()
  const maxYear = sortedYears[sortedYears.length - 1]
  const minYear = sortedYears[0] || sortedYears[1] // Minimum non-zero year
  if (sortedYears.includes(actualYear)) {
    return actualYear
  }
  if (actualYear > maxYear) {
    return sortedYears.includes(0) ? 0 : maxYear
  }
  // Implicitly actual year < minYear
  return minYear
}

const y67tPaperApplicationModule = {
  state: {
    overCap: null,
    contactTypes: [],
    pnrApplication: JSON.parse(JSON.stringify(basePNRApp)),
    eoiApplication: JSON.parse(JSON.stringify(baseEOIApp)),
    formReferenceData: [],
    allStates: [],
    applicationType: null,
    predictiveSchools: null,
    relationshipTypes: [],
    titles: [],
    highSchools: [],
    pilotSchools: [],
    applicationSubmitted: false,
    applicationID: null,
    schoolCustomisations: {},
    isSubmitting: false,
    isFileUploadInProgress: null,
    isFileDeleteInProgress: null
  },
  getters: {
    overCap: (state) => state.overCap,
    applicationID: (state) => state.applicationID,
    contactTypes: (state) => state.contactTypes,
    pnrApplication: (state) => state.pnrApplication,
    eoiApplication: (state) => state.eoiApplication,
    formReferenceData: (state) => state.formReferenceData,
    allStates: (state) => state.allStates,
    applicationType: (state) => state.applicationType,
    relationshipTypes: (state) => state.relationshipTypes,
    titles: (state) => state.titles,
    currentStep: (state) => state.eoiApplication.meta.currentStep,
    validStudent: (state) => state.eoiApplication.meta.validStudent,
    validParentCarers: (state) => state.eoiApplication.meta.validParentCarers,
    validHighSchool: (state) => state.eoiApplication.meta.validHighSchool,
    validConsiderations: (state) =>
      state.eoiApplication.meta.validConsiderations,
    localHighSchools: (state) =>
      state.eoiApplication.meta.localSecondarySchools,
    consideringSelectiveSchool: (state) =>
      state.eoiApplication.consideringSelectiveSchool,
    consideringOoAGovtSchools: (state) =>
      state.eoiApplication.consideringOoAGovtSchools,
    ooaHighSchools: (state) => state.eoiApplication.ooaHighSchools,
    highSchools: (state) => state.highSchools,
    pilotSchools: (state) => state.pilotSchools,
    predictiveSchools: (state) => state.predictiveSchools,
    applicationSubmitted: (state) => state.applicationSubmitted,
    // This will need to be updated to be dynamic based on the steps in the form.
    validForm: (state, getters) =>
      getters.validParentCarers &&
      getters.validStudent &&
      (getters.validHighSchool !== null ? getters.validHighSchool : true) &&
      (getters.validConsiderations !== null
        ? getters.validConsiderations
        : true),
    schoolCustomisations: (state) => state.schoolCustomisations,
    isSubmitting: (state) => state.isSubmitting,
    supportingDocuments: (state) => state.eoiApplication.supportingDocuments,
    isFileUploadInProgress: (state) => state.isFileUploadInProgress,
    isFileDeleteInProgress: (state) => state.isFileDeleteInProgress
  },
  mutations: {
    setApplicationID(state, id) {
      state.applicationID = id
    },
    setOverCap(state, value) {
      state.overCap = value
    },
    setContactTypes(state, types) {
      state.contactTypes = types
    },
    setApplicationType(state, type) {
      state.applicationType = type
    },
    setEoIApplication(state, eoiApplication) {
      state.eoiApplication = eoiApplication
    },
    setPnrApplication(state, pnrApplication) {
      state.pnrApplication = pnrApplication
    },
    setFormReferenceData(state, data) {
      state.formReferenceData = data
    },
    setAllStates(state, states) {
      state.allStates = states
    },
    setRelationshipTypes(state, relationshipTypes) {
      state.relationshipTypes = relationshipTypes
    },
    setTitles(state, titles) {
      state.titles = titles
    },
    setCurrentStep(state, step) {
      state.eoiApplication.meta.currentStep = step
    },
    setValidStudent(state, isValid) {
      state.eoiApplication.meta.validStudent = isValid
    },
    setValidParentCarers(state, isValid) {
      state.eoiApplication.meta.validParentCarers = isValid
    },
    setValidHighSchool(state, isValid) {
      state.eoiApplication.meta.validHighSchool = isValid
    },
    setValidConsiderations(state, validConsiderations) {
      state.eoiApplication.meta.validConsiderations = validConsiderations
    },
    setLocalHighSchool(state, localHighSchool) {
      state.eoiApplication.localHighSchool = localHighSchool
    },
    setLocalHighSchools(state, localHighSchools) {
      state.localHighSchools = localHighSchools
    },
    setConsideringSelectiveSchool(state, value) {
      state.eoiApplication.consideringSelectiveSchool = value
    },
    setConsideringOoAGovtSchools(state, value) {
      state.eoiApplication.consideringOoAGovtSchools = value
    },
    setOoaHighSchools(state, ooaSchools) {
      state.eoiApplication.ooaHighSchools = ooaSchools
    },
    setHighSchools(state, schools) {
      state.highSchools = schools
    },
    setPilotSchools(state, schools) {
      state.pilotSchools = schools
    },
    setSelectedLocalSecondarySchool(state, value) {
      state.eoiApplication.localHighSchool = {
        schoolCode: value.school_code,
        schoolName: value.school_name,
        catchmentLevel: 'secondary'
      }
    },
    setPredictiveSchools(state, schools) {
      state.predictiveSchools = schools
    },
    setIntendedHighSchool(state, school) {
      state.pnrApplication.intendedHighSchool = school
    },
    setApplicationSubmitted(state, status) {
      state.applicationSubmitted = status
    },
    setSchoolCustomisation(state, { schoolCode, customisation }) {
      if (!state.schoolCustomisations[schoolCode]) {
        // init the object
        // eslint-disable-next-line import/no-named-as-default-member
        Vue.set(state.schoolCustomisations, schoolCode, [])
      }
      // eslint-disable-next-line import/no-named-as-default-member
      Vue.set(state.schoolCustomisations, schoolCode, customisation)
    },
    setIsSubmitting(state, submitting) {
      state.isSubmitting = submitting
    },
    setSupportingDocuments(state, supportingDocuments) {
      state.eoiApplication.supportingDocuments = supportingDocuments
    },
    setIsFileUploadInProgress(state, inProgress) {
      state.isFileUploadInProgress = inProgress
    },
    setIsFileDeleteInProgress(state, inProgress) {
      state.isFileDeleteInProgress = inProgress
    }
  },
  actions: {
    getReferenceDataForPaperEoI({ commit, dispatch }) {
      dispatch('getReferenceData').then((response) => {
        commit('setFormReferenceData', response)
        dispatch('getContactTypes')
        dispatch('getRelationshipTypes')
        dispatch('getStates')
        dispatch('getTitles')
      })
    },
    getContactTypes({ commit, getters }) {
      const { formReferenceData } = getters
      if (!formReferenceData) {
        commit('setContactTypes', [])
      }
      const contactTypes = formReferenceData[DATASET.CONTACT_TYPE]
      if (contactTypes.length > 0) {
        const y67tContactTypes = ['PHMOB', 'PHHOM', 'PHWRK']
        const types = contactTypes.filter(
          (ct) => y67tContactTypes.indexOf(ct.value) !== -1 && !ct.hidden
        )
        commit('setContactTypes', types)
      }
    },
    getRelationshipTypes({ commit, getters }) {
      const { formReferenceData } = getters
      if (!formReferenceData) {
        commit('setRelationshipTypes', [])
      }
      const relationshipTypes = formReferenceData[DATASET.RELATIONSHIP]
      if (relationshipTypes.length > 0) {
        const relTypes = relationshipTypes.filter((rel) => !rel.hidden)
        commit('setRelationshipTypes', relTypes)
      }
    },
    getTitles({ commit, getters }) {
      const { formReferenceData } = getters
      if (!formReferenceData) {
        commit('setTitles', [])
      }
      const allTitles = formReferenceData[DATASET.TITLE]
      if (allTitles.length > 0) {
        const titles = allTitles.filter((title) => !title.hidden)
        commit('setTitles', titles)
      }
    },
    getStates({ commit, getters }) {
      const { formReferenceData } = getters
      if (formReferenceData) {
        const labels = {
          nswNonGovtSchool: 'NSW non-government school',
          overseasSchool: 'Overseas school',
          other: 'Other'
        }

        let allStates = formReferenceData[DATASET.STATES]
        // Remap from retrieved API key/vals to useable key/vals
        allStates = allStates
          .filter(
            (child) =>
              child.value !== 'OTH' &&
              child.value !== 'XXX' &&
              child.value !== 'ZZZ'
          )
          .map((child) => {
            const newPropsObj = {
              text: child.text,
              value: child.value
            }

            if (child.text === 'New South Wales') {
              newPropsObj.text = labels.nswNonGovtSchool
            } else if (child.text === 'Overseas') {
              newPropsObj.text = labels.overseasSchool
            } else if (child.text === 'Other/Not Applicable') {
              newPropsObj.text = labels.other
            }

            return newPropsObj
          })
        commit('setAllStates', allStates)
      } else {
        commit('setAllStates', [])
      }
    },
    getPredictiveSchools({ commit, dispatch }) {
      dispatch('getAllAuSchools').then((response) => {
        commit('setPredictiveSchools', response)
      })
    },
    getHighSchools({ commit, dispatch }) {
      dispatch('getHighSchoolsRefData').then((response) => {
        commit('setHighSchools', response)
      })
    },
    getPilotSchools({ commit, dispatch }) {
      dispatch('getAllSchools').then((response) => {
        commit('setPilotSchools', response.data)
      })
    },
    updateCapacityForOOASchools({ getters, commit }) {
      const { pilotSchools, schoolCustomisations } = getters
      const modifiedCapacityData = getters.ooaHighSchools?.map((school) => {
        const item = school
        const schoolCustomisation =
          this.schoolCustomisations?.[school.schoolCode]
        if (schoolCustomisation?.ooa?.capacity?.code) {
          item.capacity = schoolCustomisations[school.schoolCode].capacity.code
          return item
        } else {
          const pilotSchool = pilotSchools.find(
            (element) => element.schoolCode === school.schoolCode
          )
          item.capacity = pilotSchool?.capStatus
          return item
        }
      })
      commit('setOoaHighSchools', modifiedCapacityData)
    },
    setApplicationID({ commit }, applicationID) {
      commit('setApplicationID', applicationID)
    },
    setApplicationSubmitted({ commit }, status) {
      commit('setApplicationSubmitted', status)
    },
    setCurrentStep({ commit }, currentStep) {
      commit('setCurrentStep', currentStep)
    },
    setValidStudent({ commit }, isValid) {
      commit('setValidStudent', isValid)
    },
    setValidParentCarers({ commit }, isValid) {
      commit('setValidParentCarers', isValid)
    },
    setValidHighSchool({ commit }, isValid) {
      commit('setValidHighSchool', isValid)
    },
    setValidConsiderations({ commit }, isValid) {
      commit('setValidConsiderations', isValid)
    },
    setApplicationType({ commit }, type) {
      commit('setApplicationType', type)
    },
    setEoIApplication({ commit }, eoiApplication) {
      commit('setEoIApplication', eoiApplication)
    },
    setLocalHighSchool({ commit }) {
      commit('setLocalHighSchool', localStorage.getItem('localHighSchoolDraft'))
    },
    setOoaHighSchools({ commit }, ooaSchools) {
      commit('setOoaHighSchools', ooaSchools)
    },
    setSelectedLocalSecondarySchool({ commit }, school) {
      commit('setSelectedLocalSecondarySchool', school)
    },
    setIntendedHighSchool({ commit }, school) {
      commit('setIntendedHighSchool', school)
    },
    setConsideringSelectiveSchool({ commit }, consideringSelectiveSchool) {
      commit('setConsideringSelectiveSchool', consideringSelectiveSchool)
    },
    setConsideringOoAGovtSchools({ commit }, consideringOoAGovtSchools) {
      commit('setConsideringOoAGovtSchools', consideringOoAGovtSchools)
    },
    setSupportingDocuments({ commit }, supportingDocuments) {
      commit('setSupportingDocuments', supportingDocuments)
    },
    setIsFileUploadInProgress({ commit }, { inProgress }) {
      commit('setIsFileUploadInProgress', inProgress)
    },
    setIsFileDeleteInProgress({ commit }, { inProgress }) {
      commit('setIsFileDeleteInProgress', inProgress)
    },
    createAdvice({ commit, getters, dispatch }, item) {
      dispatch('setApplicationID', null)
      let application = null
      const { applicationType } = getters
      applicationType === 'EOI'
        ? (application = JSON.parse(JSON.stringify(baseEOIApp)))
        : (application = JSON.parse(JSON.stringify(basePNRApp)))

      const { selectedSchool } = getters
      const { schoolCode, schoolName, catchmentLevel } = selectedSchool
      application.currentPrimarySchool = {
        schoolCode,
        schoolName,
        catchmentLevel
      }
      application.student = item.selectedItem.student
      if (application.student.middleName === 'null') {
        application.student.middleName = ''
      }

      application.parentCarers = item.selectedItem.parentCarers
      if (application.parentCarers) {
        application.parentCarers[0].parentCarerCompletingApp = true
      }

      const parentCarer = application.parentCarers[0]
      const { contactDetails } = parentCarer

      if (contactDetails.length === 0 || parentCarer.checkERN) {
        parentCarer.contactDetails = [{}]
      }

      parentCarer.parentCarerEmail =
        parentCarer.parentCarerEmail === 'null'
          ? ''
          : parentCarer.parentCarerEmail
      parentCarer.parentCarerRelation =
        parentCarer.parentCarerRelation === 'null'
          ? ''
          : parentCarer.parentCarerRelation
      parentCarer.parentCarerTitle =
        parentCarer.parentCarerTitle === 'null'
          ? ''
          : parentCarer.parentCarerTitle
      parentCarer.parentCarerFamilyName =
        parentCarer.parentCarerFamilyName === 'null'
          ? ''
          : parentCarer.parentCarerFamilyName
      parentCarer.parentCarerGivenName =
        parentCarer.parentCarerGivenName === 'null'
          ? ''
          : parentCarer.parentCarerGivenName

      application.residentialAddress = item.selectedItem.residentialAddress
      applicationType === 'EOI'
        ? commit('setEoIApplication', application)
        : commit('setPnrApplication', application)
      dispatch('getLocalHighSchoolForAddress')
    },
    submitAdvice({ getters, commit, dispatch }, submission) {
      commit('setIsSubmitting', true)
      let application = UTILS.clone(submission)
      application.student.dateOfBirth = converToISODate(
        application.student.dateOfBirth
      )
      const { applicationType } = getters
      if (
        applicationType === 'PNR' &&
        UTILS.isEmpty(application.localHighSchool)
      ) {
        delete application.localHighSchool
      }
      if (applicationType === 'EOI') {
        application.consideringOoAGovtSchools =
          application.consideringOoAGovtSchools === null
            ? false
            : application.consideringOoAGovtSchools
        application.localHighSchool = application.localHighSchool
          ? {
              schoolCode: application.localHighSchool.schoolCode.toString(),
              schoolName: application.localHighSchool.schoolName,
              catchmentLevel: application.localHighSchool.catchmentLevel
            }
          : null
        if (
          application.ooaHighSchools?.length === 0 &&
          application.supportingDocuments.digitizeEoiConsiderations?.length > 0
        ) {
          const fileName =
            application.supportingDocuments.digitizeEoiConsiderations[0]?.name
          const fileType =
            application.supportingDocuments.digitizeEoiConsiderations[0]?.type
          dispatch('deleteY67TSupportingDocument', {
            file: { name: fileName, type: fileType },
            schoolCode: application.currentPrimarySchool?.schoolCode,
            applicationId: application.student?.SRN,
            uploadedBy: `${store.state.userID}@det.nsw.edu.au`,
            stream: application.meta?.stream
          })
            .then(() => {
              dispatch('setSupportingDocuments', {
                digitizeEoiConsiderations: []
              })
            })
            .catch(() => {
              store.dispatch('showMessageBox', {
                icon: 'mdi-exclamation-thick',
                html: '<h2>Considerations document uploaded could not be removed.</h2>'
              })
            })
          application.supportingDocuments = {
            digitizeEoiConsiderations: []
          }
        }
        application.applicationStatus = 'New'
        delete application.meta
      }
      API.get(`/student/${application.student.SRN}/address`, true)
        .then((resp) => {
          if (resp && resp.data) {
            const ernAddressRecord =
              resp.data.residentialAddress.ernAddressRecordNo
            let date = new Date().toISOString().slice(0, 10)
            const residentialAddress = {
              addressLine1: application.residentialAddress.addressLine1,
              suburbName: application.residentialAddress.suburbName,
              stateCode: application.residentialAddress.stateCode,
              postCode: application.residentialAddress.postCode,
              countryCode: application.residentialAddress.countryCode,
              validationFlag: 'V',
              startDate: application.residentialAddressUpdated
                ? date
                : resp.data?.residentialAddress?.startDate,
              endDate: application.residentialAddressUpdated
                ? '2999-12-31'
                : resp.data?.residentialAddress?.endDate, //harcoded as of now (need this for ERN tracking),
              addressId: application.residentialAddress.addressId,
              latitude: application.residentialAddress.latitude,
              longitude: application.residentialAddress.longitude,
              ernAddressRecordNo: application.residentialAddressUpdated
                ? null
                : ernAddressRecord
            }
            application.residentialAddress = residentialAddress
            submission.residentialAddress = residentialAddress
          }
        })
        .then(() => {
          dispatch('submitApplication', application)
        })
    },
    submitApplication({ getters, dispatch }, application) {
      const { applicationType, selectedSchool, applicationID } = getters
      const { schoolCode } = selectedSchool
      const submitAPI =
        applicationType === 'EOI'
          ? `${process.env.VUE_APP_API_PI}/v1/y67t/eoi/submit/staff`
          : `${process.env.VUE_APP_API_PI}/v1/y67t/pnr/submit/staff`
      let setApplicationSubmittedStatus = false
      if (
        applicationType === 'PNR' &&
        UTILS.isEmpty(application.localHighSchool)
      ) {
        setApplicationSubmittedStatus = true
      }

      API.post(submitAPI, { application }, false, { schoolCode })
        .then((response) => {
          if (setApplicationSubmittedStatus) {
            dispatch('setApplicationSubmitted', true)
          } else {
            const eoiID = !UTILS.isEmpty(response.data.body.eoiId)
              ? response.data.body.eoiId
              : applicationID
            dispatch('setApplicationID', eoiID)
            if (UTILS.isEmpty(eoiID)) {
              throw new Error('EOI ID not found')
            } else {
              dispatch('setApplicationSubmitted', true)
            }
          }
        })
        .catch((error) => {
          const response = error.response && error.response.data
          const isAddressUpdateFailed = response?.body?.addressUpdateFailed
          if (isAddressUpdateFailed) {
            store.dispatch('showMessageBox', {
              icon: 'mdi-exclamation-thick',
              html: '<h2>Address cannot be updated. Please try again later.</h2>'
            })
          } else {
            store.dispatch('showMessageBox', {
              icon: 'mdi-exclamation-thick',
              html: `<h2>${
                (response && response.title) || 'Unable to complete request'
              }</h2>${(response && response.message) || error || ''}`
            })
          }
        })
    },
    getLocalHighSchoolForAddress({ commit, getters, dispatch }) {
      const { pnrApplication, eoiApplication, applicationType } = getters
      const application =
        applicationType === 'EOI' ? eoiApplication : pnrApplication
      let ernAddr = application.residentialAddress
      let residentialAddress = {}
      const addressLine2 =
        ernAddr.addressLine2 && ernAddr.addressLine2 !== 'null'
          ? ernAddr.addressLine2
          : ''
      residentialAddress.addressLine1 = ernAddr.addressLine1 + addressLine2
      residentialAddress.countryCode = ernAddr.countryCode
      residentialAddress.postCode = ernAddr.postCode
      residentialAddress.stateCode = ernAddr.stateCode
      residentialAddress.suburbName = ernAddr.suburbName
      residentialAddress.startDate = ernAddr.startDate
      let ernAddressString = [
        CONFLICT.normaliseAddress(residentialAddress.addressLine1),
        residentialAddress.suburbName,
        residentialAddress.stateCode,
        residentialAddress.postCode
      ].join(' ')
      ernAddressString = CONFLICT.formatWithoutAbbreviation(ernAddressString)
      // Call NSW Point API to get the addressId for ERN address
      const params = {
        address: ernAddressString,
        maxNumberOfResults: 5,
        addressType: 'physical'
      }
      return API.get(
        `${process.env.VUE_APP_API_PI}/nswpoint/v2/api/predictive1`,
        true,
        {
          'x-preflight': 'force',
          noDefaultHeader: true
        },
        params
      ).then((resp) => {
        if (resp.data.length === 0) {
          return commit('setLocalHighSchool', null)
        }
        if (resp && resp.data && resp.data.length > 0) {
          // get the 0th record from the suggested list
          const address = resp.data[0]
          residentialAddress.addressId = address.id
          return API.post(
            `${process.env.VUE_APP_API_PI}/nswpoint/v2/api/predictive2`,
            { id: address.id },
            false,
            { 'x-preflight': 'force', noDefaultHeader: true }
          ).then((resp) => {
            const geo = resp.data.data.geo
            const long = geo.geometry.coordinates[0]
            const lat = geo.geometry.coordinates[1]
            residentialAddress.longitude = long
            residentialAddress.latitude = lat
            application.residentialAddress = residentialAddress
            applicationType === 'EOI'
              ? commit('setEoIApplication', application)
              : commit('setPnrApplication', application)
            dispatch('getLocalHighSchool', [long, lat])
          })
        }
      })
    },
    getLocalHighSchool({ getters, commit }, [long, lat]) {
      const { pnrApplication, eoiApplication, applicationType, oesProperties } =
        getters
      const application =
        applicationType === 'EOI' ? eoiApplication : pnrApplication
      const schoolFinderURLs =
        oesProperties?.endpoints?.schoolFinder?.urls || []
      const headers = {
        noDefaultHeader: true
      }
      const sqlQuery = `SELECT s.school_code, s.school_name, s.school_email, s.street, s.town_suburb, s.school_subtype, s.postcode, s.longitude, s.latitude, s.gender, phone, website, c.calendar_year, (EXTRACT(YEAR FROM now()) + 1) as next_year, ARRAY_AGG(DISTINCT all_catch.calendar_year) as all_calendar_years FROM dec_schools_2020 as s JOIN catchments_2020 as c USING (school_code) JOIN catchments_2020 AS all_catch USING (school_code,catchment_level) WHERE c.catchment_level = 'secondary' AND ST_CONTAINS(c.the_geom, ST_SetSRID(ST_Point(${long},${lat}), 4326)) GROUP BY  s.school_code, s.school_name, s.school_email, s.street, s.town_suburb,s.postcode, s.longitude, s.latitude, phone, website,s.school_subtype, c.calendar_year, s.gender;`
      return API.get(
        `${schoolFinderURLs[0]}?q=${encodeURIComponent(sqlQuery)}`,
        true,
        headers
      ).then((resp) => {
        let localHighSchool = null
        if (resp.data.rows && resp.data.rows.length > 0) {
          const data = resp.data.rows
          let intendedHighSchool = resp.data.rows[0]
          const nextYear = data?.[0]?.next_year
          // Filter out senior campus schools and set localHighSchools array to be used in Paper EOI high school selection
          let localHighSchoolsAfterFilter = data.filter((schoolCatchment) => {
            const nextYearAsDBYear = actualYearToDBYear(
              schoolCatchment.all_calendar_years,
              nextYear
            )
            return (
              schoolCatchment.calendar_year === nextYearAsDBYear &&
              SCHOOL_SUBTYPE.includes(schoolCatchment.school_subtype)
            )
          })
          if (applicationType === 'EOI') {
            application.meta.localSecondarySchools = localHighSchoolsAfterFilter
            commit('setLocalHighSchools', localHighSchoolsAfterFilter)
          }
          // check if the address returns multiple highschool records
          if (resp.data.rows.length > 1) {
            // For Paper PNRs Filter out schools based on selected gender of student
            if (applicationType === 'PNR') {
              const studentGender =
                application.student.genderCode === 'F' ? 'girls' : 'boys'
              const recommendedHighschools = localHighSchoolsAfterFilter
                .filter(
                  (school) =>
                    school.gender === studentGender || school.gender === 'coed'
                )
                .sort((a, b) => {
                  if (
                    a.school_name.includes('Girls') ||
                    a.school_name.includes('Boys')
                  ) {
                    return -1
                  } else if (
                    b.school_name.includes('Girls') ||
                    b.school_name.includes('Boys')
                  ) {
                    return 1
                  } else {
                    return 0
                  }
                })
              // Paper PNRs: Return first item in array based on gender if no gender specific school found choose first coed school in array
              intendedHighSchool = recommendedHighschools[0]
            }
          }
          localHighSchool = {
            schoolCode: intendedHighSchool.school_code.toString(),
            schoolName: intendedHighSchool.school_name
          }
          application.localHighSchool = localHighSchool
          if (!localStorage.getItem('localHighSchoolDraft')) {
            localStorage.setItem(
              'localHighSchoolDraft',
              JSON.stringify(localHighSchool)
            )
          }
          applicationType === 'EOI'
            ? commit('setEoIApplication', application)
            : commit('setPnrApplication', application)
        } else {
          application.localHighSchool = localHighSchool
          applicationType === 'EOI'
            ? commit('setEoIApplication', application)
            : commit('setPnrApplication', application)
        }
      })
    },
    async getSchoolCustomisation({ commit, dispatch }, schoolCode) {
      await dispatch('getSchoolSettingsJson', schoolCode).then(
        (customisation) => {
          commit('setSchoolCustomisation', { schoolCode, customisation })
        }
      )
    },
    async getOverCap({ commit, dispatch, getters }, schoolCode) {
      await dispatch('getSchoolCustomisation', schoolCode)
      const { pilotSchools, schoolCustomisations } = getters
      const schoolCustomisation = schoolCustomisations?.[schoolCode]
      const pilotSchool = pilotSchools.find(
        (element) => element.schoolCode === schoolCode
      )
      if (schoolCustomisation?.ooa?.capacity?.code === SCHOOL_CAPS.OVER) {
        return commit('setOverCap', true)
      }
      if (!schoolCustomisation && pilotSchool?.capStatus === SCHOOL_CAPS.OVER) {
        return commit('setOverCap', true)
      }
      commit('setOverCap', false)
    }
  }
}
export default y67tPaperApplicationModule
